.overlay{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #0016;

  .container{
    background: #fff;
    box-shadow: 0 0 15px #0002;
    border-radius: 16px;
    
    padding: 47px;

    position: relative;

    min-width: 345px;
    max-width: calc(100vw - 20px);

    text-align: center;
    font-size: 16px;

    .closeModal{
      position: absolute;
      top: 1.5rem;
      right: 1rem;
  
      background: transparent;
      border: 0;
    }
    
    svg{
      margin: 0 auto 24px;
      display: block;
    }
  }
}
.btnFinish{
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 22px 0 0;
  padding: 10px 32px;

  width: 100%;
  height: 53px;

  background: var(--color-primary);
  color: #FFFFFF !important;
  border-radius: 8px;
  border: none;

  font-family: Roboto;
  font-size: 16px;
  line-height: 33px;

  text-decoration: none;
}