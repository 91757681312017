.container{
    min-height: calc(100vh - 7.5rem);
    padding-top: 4rem;
    .title{
        font-size: 1.5rem;
    }
}
.gridContainer{
    display: grid;
    grid-template-columns: 1fr 350px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    @media(max-width: 1000px){
        grid-template-columns: 1fr;
    }
   .itemsCart{
       width: 100%;
       height: 100%;
       padding-right: 2rem;
       .cartMesage{
           font-size: 2rem;
           text-align: center;
           margin-top: 30vh;
       }
       table{
           width: 100%;
           margin-top: 1rem;
          
           border-collapse: separate;
            border-spacing: 0;
         

           tr{
              margin-bottom: 3rem;
              position: relative;
            
              td{
                font-size: 1.2rem;
                font-weight: bolder;
                border: 1px solid #c2c2c2;
               height: 108px;
               color: #262A34;
                svg{
                    cursor: pointer;
                }
                   &:nth-child(1){
                       text-align: center;
                       position: relative;
                       width: 50px;
                       border-radius: 8px 0px 0px 8px;
                       border-right: 0;
                       padding: 1rem;
                     
                       &::after{
                           content: '';
                           position: absolute;
                           right: 0;
                           top: 20px;
                           width: 1px;
                           height: 60%;
                           background-color: #c2c2c2;
                       }
                      
                   }
                   &:nth-child(2){
                   display: flex;
                   align-items: center;
                   padding-left: 10px;
                   border-left: 0;
                   border-right: 0;
                   span{
                       margin-left: 10px;
                   }
                    }
                    &:nth-child(3){
                        text-align: center;
                        position: relative;
                        width: 100px;
                        border-left: none;
                        border-right: none ;
     
                    }
                    &:nth-child(4){
                        text-align: center;
                        position: relative;
                        width: 100px;
                        border-left: none;
                        border-right: none ;
                       
                            div{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border:1px solid #E1E8F3  ;
                                padding: 5px;
                                border-radius: 2rem;
                                span{
                                    margin-left: 5px;
                                    margin-right: 5px;
                                }
                                button{
                                    border-radius: 50%;
                                    width: 25px;
                                    height: 25px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: hidden;
                                    padding-top: 3px;
                                    background-color: white;
                                    border:1px solid #E1E8F3  ;
                                    
                                }

                            }
                       
     
                    }
                    &:nth-child(5){
                        text-align: center;
                        position: relative;
                        width: 100px;
                        border-left: none;
                        border-radius: 0px 8px 8px 0px;
     
                    }
                   
               }
           }

       }
      
   }
   .orderSummary{
    display: flex;
    flex-direction: column;
    table{
        margin-top: 1rem;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c2c2c2;
        border-radius: 8px;
        padding-left: 1rem;
        padding-right: 1rem;

        tr{
            th{
                border-bottom: 1px solid #c2c2c2;
                padding: 10px;

            }
            td{
                border-bottom: 1px solid #c2c2c2;
                text-align: right;
                
            }
         &:nth-last-child(1){
            th{
                border: none;
            }
            td{
                border: none;
            }
         }  
        }
    }
    .cupomContainer{
        margin-top: 1rem;
        margin-bottom: 3rem;
        .buttonGroup{
            display: flex;
            margin-top: 1rem;
            input{
                flex: 1;
                height: 41px;
                margin-right: 10px;
                border: none;
                background-color: #e1e8f3;
                padding: 10px;
                border-radius: 8px;
            }
            button{
                flex: 1;
               max-width: 91px;
               background-color: #00C1A5;
               color: white;
               border: none;
               font-weight: bolder;
               font-size: 1.4rem;
               border-radius: 8px;
               &:hover{
                   filter: brightness(0.9);
                   
               }
            }
        }
    }
    .action{
        background-color: #00C1A5;
        border: none;
        height: 53px;
        color: white;
        font-size: 1.4rem;
        font-weight: bolder;
        margin-top: 1rem;
        border-radius: 8px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    a{
       
        text-align: center;
        margin-top: 1rem;
        color: #00C1A5;
        font-weight: bolder;
        font-size: 1.5rem;
    }
}


}
.mesageCart{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 4rem;
    strong{
        font-size: 1.5rem;
    }
    a{
        background-color: #00C1A5;
        color: white;
        font-weight: bolder;
        width: 100%;
        max-width: 235px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 2rem;
        font-size: 1.3rem;
        text-decoration: none;
        &:hover{
            filter: brightness(0.9);
            color: white;
        }
    }
}
.couponApplied{
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 76px;
    border: 1px solid #E1E8F3;
    border-radius: 8px;
    margin-top: 1rem;
    font-weight: bold;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border-right: 2px solid #E1E8F3;
        &:nth-child(2){
            color: #00C1A5;
        }
        &:nth-last-child(1){
            border: none;
            cursor: pointer;
        }
    }
}
