.card{
    background-color: white;
    filter: drop-shadow(0px 12px 28px rgba(216, 224, 242, 0.8));
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    min-width: 300px;
    .star{
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
    }
    .txt{
        text-align: center;
        font-size: 1.4rem;
        color: #828BA1;
       
    }
    strong{
      font-size: 1.3rem;
        text-align: center;
        margin-top: 2rem;
    }

}