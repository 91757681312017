.container{
  a{
    color: inherit;
    text-decoration: none;
  }
  .title{
    margin: 24px 0 28px;
    font-weight: 900;
    color: #262A34;
    font-size: 24px;
    line-height: 28px;
  }
  .nav{
    list-style: none;
    padding-left: 0;
    li{
      color: #828BA1;
      padding: 8px 0;

      &.active{
        color: #00C1A5;
        font-weight: bold;
      }

      a{
        display: flex;
        align-items: center;
        
        .contentIcon{
          display: flex;
          justify-content: center;

          margin-right: 16px;
          width: 24px;
        }

        &:hover{
          color: #00C1A5;
        }
      }
    }
  }
  .info{
    margin: 28px 0;
    color: #828BA1;

    p{
      margin-bottom: 4px;
      font-size: 14px;
    }
    .socialButton{
      display: flex;
      justify-content: center;

      padding: 6px;
      margin: 8px 0;
      font-size: 16px;

      border: 1px solid #E1E8F3;
      border-radius: 6px;

      > span{
        margin-left: 8px;
      }
    }
  }
}
@media (max-width: 770px){
  .container{
    display: none;
  }
}