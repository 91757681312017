.headerRange{
    height: 120px;
    width: 100%;
    background-color: #1A1B20;
    position: absolute;
    left: 0;
}

.categoryContainer{
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
    .item{
        border: 1px solid #E1E8F3;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
        margin: 5px;
        color: #828BA1;
        font-size: 1.2rem;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover{
            background-color: #828BA1;
            color: #E1E8F3 ;
        }
    }
  .categoryItem {
    font-size: 1.5rem;
  }
}

.containerAnumciment{
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    &::-webkit-scrollbar{
        width: 0px;
    }

    .row{
        display: flex;

        .col{
            scroll-snap-align: center;
            flex: 1;
            padding: 10px;
            .item{
                border-radius: 8px;
                overflow: hidden;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-width: 300px;

                box-shadow: 0px 16px 24px rgba(216, 224, 242, 0.9);
                .img{
                    position: relative;
                    width: 100%;
                    background-color: #333;
                    border-radius: 8px;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    .top{
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        padding: 1rem;
                        .date{
                          background-color: var(--color-primary-dark-transparent);
                           display: flex;
                           align-items: center;
                            border-radius: 8px;
                          color: var(--color-text-medium);

                          padding: 0.8rem 1rem;
                          font-size: 1.4rem;
                          font-weight: bold;

                          span {
                            font-size: 1.4rem;
                            color: var(--color-text-medium);
                          }
                            svg{
                                margin-right: 10px;
                            }
                        }
                        button{
                            width: 34px;
                            height: 34px;
                            border-radius: 50%;
                            border: none;
                            background-color: #1a1b20a4;
                        }
                    }
                    &::after{
                        content: '';
                        display: block;
                        padding-top: 65%;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 70%;
                        background: linear-gradient(180deg, rgba(38, 42, 52, 0.94) 0%, rgba(38, 42, 52, 0) 72.35%);
                    }
                                       
                }
                strong{
                    margin-top: 1rem;
                    font-size: 1.5rem;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    color: #262A34;
                }
                .call{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    justify-content: space-evenly;
                  
                    div{
                        display: flex;
                        flex-direction: row;
                      div {
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;
                        margin-right: 10px;

                        span{
                          font-size: 1.5rem;
                          color: #828BA1;

                        }
                        strong{
                          font-size:2rem;
                          margin: 0;
                          padding: 0;
                          text-align: left;
                          color: #262A34;
                        }
                      }

                    }
                    .btn{
                       flex: 1;
                       max-width: 144px;
                       height: 41px;
                       border-radius: 8px;
                       background-color: #00C1A5;
                       border: none;
                       color: white;
                       font-size: 1.5rem;
                       font-weight: bold;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       text-decoration: none;
                    }
                }

            }
            @media(max-width: 600px){
            max-width:calc( 8.333% * 12);
            min-width:calc( 8.333% * 12)
            }
            @media(min-width: 600px){
            max-width:calc( 8.333% * 6);
            min-width:calc( 8.333% * 6);

            }
            @media(min-width: 1281px){
            max-width:calc( 8.333% * 4);
            min-width:calc( 8.333% * 4)

            }

        }
    }
}
.containerBrands{
    display: flex;
     overflow-x: scroll;

     scrollbar-width: none;
     scroll-snap-type: x mandatory;
     padding: 1rem;

     &::-webkit-scrollbar{
         width: 0px;
     }
     @media(min-width: 600px){
         justify-content: center;
         align-items: center;

     }
    span{
        scroll-snap-align: center;
        background-color: #262A34;
        color: #828BA1;
        padding: 1rem 1.5rem;
        font-size: 2rem;
        white-space: nowrap;
        margin-right: 1.5rem;
        font-weight: bold;
        border-radius: 8px;
        min-width: 135px !important;
        text-align: center;
    }
}
.objectionBreak{
    display:flex;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @media(max-width:768px){
        flex-direction: column;
    }
    .content{
        display: flex;
        height: 100%;
        width: 100%;
        @media(max-width:600px){
            flex-direction: column;
            margin-bottom: 4rem;
        }
        .img{
            flex: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            img{
                width: 100%;
            }
        }
        .txt{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2rem;
          p {
            font-size: 1.5rem;
            color: #828BA1;
          }
          strong{
              font-size: 2rem;
              color: #262A34;
              margin-bottom: 1rem;
          }
          a{
              color: #00C1A5;
              font-size: 1.5rem;
              font-weight: bold;
              text-decoration: none;
              margin-top: 2rem;
              svg{
                  margin-left: 10px;
              }
          }
        }
    }
}
.testimonyContainer{
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    margin-top: 1rem;
    margin-bottom: 4rem;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar{
        width: 0;
        background-color: transparent;
    }

    .content{
        scroll-snap-align: center;
       padding: 1rem;
    }
}