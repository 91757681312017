.main{
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    min-height: 100vh;
    padding-top: 6rem;
    .orderSummary{
        display: flex;
        flex-direction: column;
        table{
            margin-top: 1rem;
            border-collapse: separate;
            border-spacing: 0;
            border: 1px solid #c2c2c2;
            border-radius: 8px;
            padding-left: 1rem;
            padding-right: 1rem;
    
            tr{
                th{
                    border-bottom: 1px solid #c2c2c2;
                    padding: 10px;
    
                }
                td{
                    border-bottom: 1px solid #c2c2c2;
                    text-align: right;
                    
                }
             &:nth-last-child(1){
                th{
                    border: none;
                }
                td{
                    border: none;
                }
             }  
            }
        }
        .cupomContainer{
            margin-top: 1rem;
            margin-bottom: 3rem;
            .buttonGroup{
                display: flex;
                margin-top: 1rem;
                input{
                    flex: 1;
                    height: 41px;
                    margin-right: 10px;
                    border: none;
                    background-color: #e1e8f3;
                    padding: 10px;
                    border-radius: 8px;
                }
                button{
                    flex: 1;
                   max-width: 91px;
                   background-color: #00C1A5;
                   color: white;
                   border: none;
                   font-weight: bolder;
                   font-size: 1.4rem;
                   border-radius: 8px;
                   &:hover{
                       filter: brightness(0.9);
                       
                   }
                }
            }
        }
        .action{
            background-color: #00C1A5;
            border: none;
            height: 53px;
            color: white;
            font-size: 1.4rem;
            font-weight: bolder;
            margin-top: 1rem;
            border-radius: 8px;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        a{
           
            text-align: center;
            margin-top: 1rem;
            color: #00C1A5;
            font-weight: bolder;
            font-size: 1.5rem;
        }
    }
}
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: saturate(100%) blur(2px);
    backdrop-filter: saturate(100%) blur(2px);
    .modalContent{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 495px;
        text-align: center;
        box-shadow: 0px 4px 16px rgba(216, 224, 242, 0.8);
        padding: 2rem;
        border-radius: 16px;
        strong{margin-top: 3rem; margin-bottom: 3rem; font-size: 1.5rem;}
        span{
            margin-bottom: 3rem;
            font-size: 1.3rem;
        }
        a{ 
            background-color: #00C1A5;
            color: white;
            width: 100%;
            max-width: 225px;
            padding: 1rem 2rem;
            font-weight: bolder;
            font-size: 1.5rem;
            border: none;
            border-radius: 8px;
            text-decoration: none;
        }

    }
}

.cardInfo{
    display: flex;
    border: 1px solid #c2c2c2;
    padding: 1rem;
    border-radius: 8px;
}