.container {
  height: 120px;
  background-color: #1A1B20;
  display: flex;
  align-items: center;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;
      align-items: center;

      .logo {
        display: inline-flex;


        svg {
          @media(max-width: 600px) {
            width: 100px;

          }
        }
      }

    }

    .openMenu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 24px;
      height: 20px;
      margin-right: 1rem;

      div {
        width: 100%;
        height: 2px;
        background-color: #828BA1;
      }

      @media(min-width: 1100px) {
        display: none;
      }
    }

    .search {
      width: 100%;
      max-width: 455px;
      margin: 20px;
      background-color: #262A34;
      display: flex;
      align-items: center;
      border-radius: 8px;
      overflow: hidden;
      @media(max-width: 850px) {
        width: auto;
        background-color: transparent;

      }

      .hide {
        width: 100%;
        overflow: hidden;
        display: flex;

        .close {
          border-radius: 50%;
          margin-left: 10px;
        }

        .searvhTwo {
          margin-right: 10px;
          position: relative;


        }

        @media(max-width: 850px) {
          position: absolute;
          width: 90%;
          border-radius: 8px;
          background-color: #262A34;
          left: 5%;
          z-index: 99;
          align-items: center;

        }

        input {
          width: 100%;
          height: 40px;
          border: none;
          background-color: transparent;
          outline: none;
          padding: 1rem;
          font-size: 1.5rem;
          color: #828BA1;
          border-right: 1px solid #828BA1;
          margin: 5px 10px;
          @media(min-width: 850px) {
            border: none;
          }
        }


      }

      button {
        height: 30px;
        width: 30px;
        background-color: transparent;
        border-left: 1px solid #828BA1;
        border-right: none;
        border-top: none;
        border-bottom: none;
        margin-right: 5px;
        @media(max-width: 850px) {
          border: none;
        }


      }
    }

    .options {

      .loggedOut {
        display: flex;
        align-items: center;

        a {
          padding: 1rem;
          color: #828BA1;
          text-decoration: none;
          font-size: 1.5rem;
        }
      }

      .Logged {
        display: flex;
        align-items: center;

        .amountCoins {
          display: flex;
          align-items: center;
          border: 1px solid #828ba17a;
          padding: 0.5rem 1rem;
          border-radius: 8px;
          margin-right: 20px;
          min-width: 140px;

          div {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            span {
              color: #828BA1;
            }

            strong {
              color: white;
            }
          }
        }

        .user {
          color: #828BA1;
          text-decoration: none;
          font-size: 1.5rem;

          position: relative;

          svg {
            margin-right: 5px;
          }

        }
      }
    }
  }

  .hideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: flex;
    overflow: hidden;
    transition: all ease 0.5s;

    .content {
      width: 70%;
      max-width: 269px;
      border-radius: 0px 16px 0px 0px;
      box-shadow: 24px 0px 32px rgba(24, 26, 32, 0.25);
      height: 100%;
      background-color: #333;
      position: relative;

      .user {
        height: 114px;
        border-bottom: 1px solid #828BA1;
        display: flex;
        align-items: center;
        margin: 2rem;

        .Logged {
          display: flex;

          .containerIco {
            background-color: #00C1A5;
            height: 45px;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: white;
            margin-right: 10px;
          }

          .userName {
            display: flex;
            flex-direction: column;

            small {
              color: #828BA1;
              font-size: 1.4rem;
            }

            span {
              font-size: 1.8rem;
              color: white;
              font-weight: bolder;
            }
          }
        }

        .loggedOut {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          svg {
            margin-bottom: 10px;
            background-color: #00C1A5;
            width: 45px;
            height: 45px;
            padding: 5px;
            border-radius: 50%;
          }

          a {
            color: #828BA1;
            text-decoration: none;
            font-size: 1.5rem;
          }
        }
      }

      .list {
        li {
          list-style: none;
          color: #D8E0F2;
          margin-bottom: 20px;

          .icon{
            display: flex;
            justify-content: left;
            height: 24px;
          }

          a {
            text-decoration: none;
            font-size: 1.7rem;
            color: #D8E0F2;

            svg {
              margin-right: 10px;
            }
          }

          p.qtdcoins {
            text-align: center;
            font-size: 1.5rem;
            padding-right: 2rem;
            span {
              color: white;
            }
          }
          div.containerButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 2rem;

            .btn {
              color: #00C1A5;
              border: none;
              width: 100%;
              border-radius: 8px;
              text-align: center;
              text-decoration: none;
              font-weight: bold;
            }
          }
        }
      }

      footer {
        position: absolute;
        bottom: 0;
        background-color: #00C1A5;
        width: 100%;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          color: white;
          text-decoration: none;
          font-size: 1.5rem;
          display: flex;
          align-items: center;

          span {
            width: 15px;
            height: 15px;
            border: 1px solid white;
            border-radius: 50%;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 900;
            margin-right: 10px;


          }
        }
      }
    }

    .closeArea {
      flex: 1;
      position: relative;
      background-color: transparent;

      svg {
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
      }
    }
  }
}

.cart {

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 2rem;
  cursor: pointer;

  .qtdItem {
    position: absolute;
    top: 0px;
    right: -3px;
    background-color: #FD6151;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
.cartNotLogged {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;

  .qtdItemNotLogged {
    position: absolute;
    top: 10px;
    right: 6px;
    background-color: #FD6151;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.userMenu {
  position: absolute;
  width: 227px;
  height: auto;
  top: 0;
  right: 0;
  background-color: #262A34;
  box-shadow: 0px 16px 28px rgba(24, 26, 32, 0.32);
  padding: 2rem;
  border-radius: 16px;
  z-index: 9999;

  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .user {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    margin-top: 2rem;

    span {
      color: white;
      font-weight: bold;
      font-size: 2rem;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      height: 40px;
      display: flex;

      .qtdLeiloes {
        span {
          min-width: 25px;
          min-height: 25px;
          background-color: #181A20;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          border-radius: 8px;
        }
      }

      margin-bottom: 1rem;

      a {
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;

        color: #828BA1;
        font-weight: bolder;
      }
    }
  }

  .qtdcoins {
    span {
      color: white;
    }
  }

  .containerButton {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      padding: 2rem 1rem;
      background-color: #00C1A5;
      border: none;
      width: 100%;
      color: white;
      border-radius: 8px;
      text-align: center;
      text-decoration: none;
      font-weight: bold;
    }
  }
}

@media(min-width: 850px) {
  .close {
    display: none;
  }
  .searvhTwo {
    display: none;
  }
}

@media(max-width: 1100px) {
  .amountCoins {
    display: none !important;
  }
  .txtAcount {
    display: none;
  }

}