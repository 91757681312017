.content{
  font-size: 16px;
  display: flex;

  main{
    padding: 24px 16px;
    color: #828BA1;
    flex: 1;
    max-width: 100%;
    
    .title{
      color: #262A34;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 40px;
    }

  }
}

.formCard{
  width: 350px;      
  max-width: 100%;
  margin: auto;

  .fieldGroup{
    display: flex;
    gap: 16px;
    max-width: 100%;

    .formGroup{
      max-width: calc(50% - 8px);
    }
  }

  .formGroup{
    margin: 8px 0;
    label{
      font-size: 12px;
      line-height: 14px;
      color: #828BA1;
      padding: 8px 0;
    }
    width: 100%;

    .formControl{
      width: 100%;
      background: #E1E8F3;
      border-radius: 8px;
      padding: 10px 0 10px 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      
      svg{
        max-width: 28px;
        max-height: 20px;
      }

      input{
        min-width: inherit;
        border: 0;
        height: 100%;
        background: transparent;
        outline: none;
      }
    }
  }
}

.containerCards{
  width: 350px;
  padding: 10px;
  max-width: 100%;

  margin: auto;

  .contentCard{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border: 1px solid #E1E8F3;
    box-sizing: border-box;
    border-radius: 8px;

    padding: 10px 20px;
    margin: 8px 0;
    
    svg{
      max-width: 28px;
      max-height: 20px;
    }

    &:first-child{
      margin-top: 0;
    }

    > span{
      flex: 1;
      padding: 0 10px;
      font-size: 14px;
    }
  }
}

.showCard{
  width: 350px;      
  max-width: 100%;
  margin: auto;
  padding: 10px;

  .contentCard{
    border: 1px solid #E1E8F3;
    box-sizing: border-box;
    border-radius: 20px;

    width: 345px;
    max-width: 100%;
    height: 218px;
    padding: 45px 45px 30px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cardHeader{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      svg{
        width: 44.8px;
        height: 32px
      }
    }

    .cardFooter{
      font-size: 12px;
      line-height: 14px;
      
      strong{
        display: block;
        margin: 10px 0 0;
      }     
    }
  }

  .actions{
    margin: 25px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button{
      margin: 5px;
      padding: 5px 15px;

      color: #828BA1;

      font-size: 16px;
      line-height: 33px;

      display: flex;
      align-items: center;
      
      svg{
        margin-right: 10px;
      }
    }
  }
}
// UTILS
.buttonReset{
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}
.buttonBack{
  border: none;
  background: transparent;
  margin: auto;
  padding: 5px 15px;

  color: #828BA1;

  font-size: 16px;
  line-height: 33px;

  display: flex;
  align-items: center;
  
  svg{
    margin-right: 10px;
  }
}