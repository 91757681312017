.generalContainer{
    padding-bottom: 4rem;
}
.containerproduct{  
    display: flex;
    @media(max-width: 600px){
        flex-direction: column;
    }
   
}

.listImages{
    // grid-area: option;
    flex: 1;
    max-width: 150px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    button{
       flex: 1;
       margin-bottom: 1rem;
       max-height: 50%;
       background-color: rgb(170, 170, 170);
       border: none;
       background-position: center;
       background-size: cover;
       border-radius: 8px;
       position: relative;
       overflow: hidden;
       display: flex;
       align-items: center;
       justify-content: center;
   
       .qtdImages{
           position: absolute;
           top: 0;
           left: 0;
           background-color: rgba(255, 255, 255, 0.63);
           width: 100%;
           height: 100%;
           display: flex;
           align-items: center;
           justify-content: center;
           font-size: 2rem;
           color: #075246;
           font-weight: bolder;
       }
       
    }
    @media(max-width: 1050px){
        display: none;
    }
    
    
}
.imgMobile{
    flex: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    min-height: 300px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    &::-webkit-scrollbar{
        width: 0px;
    }
    .img{
        flex: 1;
        min-width: 90%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
        position: relative;
        padding: 10px;
        scroll-snap-align: center;
        
    }
    .mediaVideo{
        scroll-snap-align: center;
        width: 100%;
        iframe{
            height: 100%;
            border-radius: 10px;
        }
    }
    .qtdIndicate{
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #929cb4;
        font-weight: bold;
        background-color: #262a3498;
        padding: 1rem;
        border-radius: 8px;
        @media(min-width:600px){
            display: none;
        }

    }
    .top{

        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(min-width: 600px){
            display: none;
        }
        .data{
            background-color: var(--color-primary-light);
            display: flex;
            align-items: center;
            padding: 0.8rem;
            border-radius: 8px;
            font-size: 1.4rem;
            color: var(--color-text-medium);
            font-weight: bold;
        }
        .icon{
            background-color: #353638a6;
            
            border-radius: 8px;
            display: flex;
            position: relative;
           span{
               position: absolute;
               top: 3px;
               left: 50%;
               width: 1px;
               height: 80%;
               background-color: #929cb4;
            
           }
           svg{
               margin: 5px;
           }
        }
    }
    

}
.imageSelect{
    // grid-area: img;
    flex: 1;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    min-height: 300px;
    display: flex;
    cursor: pointer;
    @media(min-width: 600px){
        min-width: 487px;
       
       }
       iframe{
           width: 100%;
           border-radius: 10px;
       }
    .img{
        flex: 1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
    }
    .qtdIndicate{
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: #929cb4;
        font-weight: bold;
        background-color: #262a3498;
        padding: 1rem;
        border-radius: 8px;
        @media(min-width:600px){
            display: none;
        }

    }
    .top{

        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(min-width: 600px){
            display: none;
        }
        .data{
            background-color: var(--color-primary-light);
            display: flex;
            align-items: center;
            padding: 0.8rem;
            border-radius: 8px;
            font-size: 1.4rem;
            color: var(--color-text-medium);
            font-weight: bold;
        }
        .icon{
            background-color: #353638a6;
            
            border-radius: 8px;
            display: flex;
            position: relative;
           span{
               position: absolute;
               top: 3px;
               left: 50%;
               width: 1px;
               height: 80%;
               background-color: #929cb4;
            
           }
           svg{
               margin: 5px;
           }
        }
    }
}
.productInfo{
    // grid-area: info;
    flex: 1;

    max-width: 400px;
    padding: 1rem;
    @media(max-width: 600px){
      max-width: 100%;
      padding: 0;
    }
    .contentInfo{
        border: 1px solid #E1E8F3;
       padding: 2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        @media(max-width: 600px){
           border: none;
           padding: 0;
          }
      p{
        text-align: center;
        margin-top: 10px;
        font-size: 1.3rem;
        color: var(--color-primary-light);
      }
        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;
            @media(max-width: 600px){
                display: none;
               }
            .data{
                padding: 0.8rem;
                border-radius: 8px;
              background-color: var(--color-text-light);
              display: flex;
              align-items: center;
              font-size: 1.4rem;
              color: var(--color-text-medium);
              font-weight: bold;

              span {
                font-size: 1.4rem;
                color: var(--color-text-dark);
              }
            }
            button{
                background-color: transparent;
                border: none;
            }
            svg{
                margin-right: 1rem;
                &:nth-last-child(1){
                    margin: 0;
                }
            }
        }
        .name{
            font-size: 2rem;
          
        }
        .containerAuction{
            display: flex;
            margin-top: 2rem;
            border-top: 1px solid #E1E8F3;
            border-bottom: 1px solid #E1E8F3;
            padding-top: 1rem;
            padding-bottom: 1rem;

            .auction{
                flex: 1;
                display: grid;
                grid-template-columns: 50px 1fr;
                grid-template-rows: 1fr 1fr;
                svg{
                    grid-column: 1;
                    grid-row: 1/3;
                }
                span{
                    color: #828BA1;
                    display: flex;
                    align-items: center;
                    font-size: 1.4rem;
                }
                strong{
                    font-size: 1.5rem;
                    display: flex;
                    align-items: center;
                }
            }
            .auctionUser{
                flex: 1;
                 padding-left: 10px;
                
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-left: 1px solid #E1E8F3 ;

                span{
                  color: #828BA1;
                  display: flex;
                  align-items: center;
                  font-size: 1.4rem;
                }
                strong{
                  font-size: 1.5rem;
                  display: flex;
                  align-items: center;
                }
            }
        }
        a{
            color: #828BA1;
            text-decoration: none;
            font-size: 1.2rem;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            &:nth-last-child(2){
                margin-top: 10px;
            }
        }
        .containerButton{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                width: 100%;
                border-radius: 8px;
                height: 40px;
                background-color: #00C1A5;
                border: none;
                color: white;
                font-weight: bold;
            }
        }
    }
}
.containerDescription{
    margin-top: 4rem;
    h3{
      font-size: 2rem;
      color: var(--color-primary-light);
      margin-bottom: 20px;
    }
    p{
      font-size: 1.5rem;
      color: var(--color-primary-light);
    }
    div {
      font-size: 1.4rem;
    }
}
.containerBiddingHistory{
    display: flex;
    margin-top: 4rem;
    margin-bottom: 4rem;
    @media(max-width:600px){
        flex-direction: column;
    }
    .containerForm{
        flex: 1;
        max-width: 550px;
        border: 1px solid #E1E8F3;
        border-radius: 8px;
        padding: 2rem 1rem;
        @media(min-width:600px){
          padding: 2rem;
        }
        h2{
            color: #828BA1;
        }
        table{
            width: 100%;
            border-collapse: collapse;
           
            tr{
                border-bottom: 1px solid #E1E8F3;
                &:hover td{
                    color: #00C1A5;
                    
                }
                &:nth-last-child(1){
                    border: none;
                }
               

                td{
                    padding: 1rem 0.5rem;
                    color: #828BA1;
                    cursor: pointer;
                    font-size: 1.15rem;
                    @media(min-width:600px){
                      font-size: 1.4rem;
                    }
                    &:nth-child(1){
                        font-weight: bolder;
                        svg{margin-right: 10px;}
                    }
                    &:nth-last-child(1){
                        text-align: right;
                    }
                    span {
                      font-size: 1.2rem;
                      @media(min-width:600px){
                        font-size: 1.4rem;
                      }
                    }
                }
            }
        }
    }
    .objectionBreak{
        flex: 1;
        .content{
            display: flex;
            align-items: center;
            margin-bottom: 4rem;
            padding: 2rem;
            strong{
                font-size: 2rem;
                color: #262A34;
                margin-left: 10px;
            }
            .informationLink{
                font-size: 1.4rem;
            }
            .txt{
                font-size: 1.4rem;
                margin-left: 10px;
                color: #828BA2;
            }
            .img{
                max-width: 200px;

                img{
                    width: 100%;
                }
            }
            a{
                margin-left: 10px;
                color: #00C1A5;
                font-weight: bolder;
                text-decoration: none;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 7px;
                }
            }
            &:nth-child(even) {
                flex-direction: row-reverse;
            }
        }
    }
}

.containerModalImages{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.534);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
    .carouselcontainer{
        position: relative;
        width: 90%;
        height: 90%;
        .close{
            position: absolute;
            right: 20px;
            top: 0px !important;
            z-index: 10;
           
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: none;
        }
        .qtdImgModal{
            position: absolute;
            left: 0;
            top: 0;
            color: white;
            font-weight: bolder;
        }
        .left, .rigth{
            position: absolute;
            height: 90%;
            border: none;
            background-color: transparent;
            outline: none;
           
        }
        .left{
            left: 10px;
        }
        .rigth{
            right: 10px;
        
    
        }
       .carousel{
           width: 100%;
           height: 100%;
           overflow: hidden;
           display: flex;
           align-items: center;
           justify-content: center;
           .item{
               width: 80%;
               height: 100%;
               border-radius: 8px;
               iframe{
                   width: 100%;
                   height: 100%;
               }
               img{
                   width: 100%;
               }
           }

       }
    }
}
.madalMobile{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    overflow-x: scroll;
   
    img{
        width: 100%;
       
    }
}