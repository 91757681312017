
.container{
    background-color: #1A1B20;
    padding-bottom: 3rem;
    padding-top: 4rem;
    display: none;
    @media(min-width: 600px){
        display: block;
    }
    .gridContainer{
       
        display: grid;
        @media(min-width:600px){
            grid-template-columns: 1fr 1fr  ;
            grid-template-areas: 
            'item-1 item-1' // logo
            'item-2 item-3' 
            'item-4 item-5'
            'item-6 item-6'
            'item-7 item-7'
            ;
        }
       
        @media(min-width:1200px){
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 200px;
            grid-template-areas: 
            'item-1 item-2 item-3 item-4 item-5 item-6'
            'item-7 item-7 item-7 item-7 item-7 item-6'
            ;

        }
        .item1{
            grid-area: item-1;
        
        } 
        .item2{
            grid-area: item-2;


        } 
        .item3{
            grid-area: item-3;


        } 
        .item4{
            grid-area: item-4;


        }
        .item5{
            grid-area: item-5;


        }
        .item6{
            grid-area: item-6;


        }
        .item7{
            grid-area: item-7;
            @media(max-width: 1200px){
                text-align: center;
            }

        }
    }
    .logo{
        background-color: var(--grey-darken-3);
         height: 32px;
         width: 155px;
         display: flex;
         align-items: center;
         justify-content: center;
         color: var(--grey-darken-1);
         font-family: var(--txt-font), 'sans-serif';
         font-weight: bold;
         border-radius: 8px;
         margin-bottom: 1rem;
    }
    ul{
        padding: 0;
        margin-bottom: 1rem;
        li{
            list-style: none;
            
            margin-bottom: 0.5rem;
            cursor: pointer;
            a{
                color: #D8E0F2;
                font-size: 1.2rem;
                text-decoration: none;

            }
        }
    }
    span{
        color: #D8E0F2;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    .social{
      
        svg{
            margin-right: 10px;
            margin-top: 10px;
        }
    }
    .attendance{
        display: flex;
        flex-direction: column;
        border-left: 2px solid #333;
        padding-left: 1rem;
        
       @media(max-width: 1200px){
        border-left: none;
        border-top: 2px solid #333;
        text-align: center;

       }
       
        button{
            margin-bottom: 8px;
            padding: 0.5rem;
            background-color: transparent;
            border: 1px solid #34363F;
            color: #D8E0F2;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        
            svg{
                margin-right: 5px;
            }
        }
    }
}