.breadcrumbs{
  list-style: none;
  font-size: 14px;
  padding-left: 0;

  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 12px;

  li{
    display: inline;
    color: #828BA1;
    cursor: pointer;

    a{
      text-decoration: none;
      color: inherit;
    }
    
    &:not(:last-child):after{
      content: '';
      display: inline-block;
      
      height: 8px;
      width: 8px;

      margin: auto 20px;

      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #828BA1;

      transform: rotate(315deg);
    }
    &.active{
      font-weight: bold;
    }
  }
}