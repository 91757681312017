.containerBread{
    margin-bottom: 1rem;
    margin-top: 1rem;
    ul{
        display: flex;
        margin: 0 ;
        padding: 0;
        li{
            list-style: none;
             margin-right: 20px;
             position: relative;
             display: flex;
             align-items: center;
            a{
                text-decoration: none;
                color: #828BA2;
                font-size: 14px;
                line-height: 16px;
                position: relative;
                display: inline-flex;
                align-items: center;
            }
            &::before{
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                right: -10px;
                border-right: 2px solid #828BA2 ;
                border-bottom: 2px solid #828BA2 ;
                transform: rotate( -40deg);
              }
              &:nth-last-child(1){
                &::before{
                  border: none;
                }
               
              }
        }
    }
}