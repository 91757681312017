.topPage{
    @media(max-width:600px){
        padding: 0.625rem;
    }
    // m3
    @media(min-width: 600px){
        padding: 0.983rem;
    }
    // l2
    @media(min-width: 992px){
        padding: 1.25rem;
    }
  p {
    font-size: 1.5rem;
    color: #828BA1;
  }
}
.row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    // one col = 8.333%
    .col{
        flex: 1 ;
        // s6
        @media(max-width:600px){
            max-width:calc( 8.333% * 6); 
            min-width:calc( 8.333% * 6);
            padding: 0.625rem;
           
        }
        // m3
        @media(min-width: 600px){
            max-width:calc( 8.333% * 4); 
            min-width:calc( 8.333% * 4);
            padding: 0.983rem;
        }
        // l2
        @media(min-width: 992px){
            max-width:calc( 8.333% * 3); 
            min-width:calc( 8.333% * 3);
            padding: 1.25rem;
        }
        // xl
        @media(min-width: 1500px){
            max-width:calc( 8.333% * 2); 
            min-width:calc( 8.333% * 2);
            padding: 1.25rem;

        }
        .content{
            
            display: flex;
            flex-direction: column;
            .cardContent{
                display: flex;
                flex-direction: column;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 4px 16px rgba(216, 224, 242, 0.8);
                 border-radius: 16px;
                 padding: 1rem;

                 strong{
                    font-weight: bolder;
                    font-size: 2rem;
                   
                }
                span{
                    color:  #EA8206;
                    font-weight: bold;
                    font-size: 1.5rem;
                }
                img{
                    width: 99px;
                    height: 105px;
                }
            }
            button{
               
              
                background-color: #00C1A5;
                height: 41px;
                border: none;
                border-radius: 8px;
                margin-top: 1rem;
                color: white;
                font-weight: bolder;
                font-size: 1.4rem;
                transition: 0.2s;
                &:hover{
                   filter: brightness(0.9);
                }

            }
        }
       
    }
   
}

.modalCallToAction{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   .content{
       width: 90%;
       max-width: 590px;
       height:auto;
       background-color: white;
       box-shadow: 0px 4px 16px rgba(216, 224, 242, 0.8);
       border-radius: 16px;
       display: flex;
       flex-direction: column;
       padding: 2rem 4.12rem;
       position: relative;
        .close{
            position: absolute;
            right: 15px;
            top: 15px;
            background-color: transparent;
            border: none;
        }
       .containerTable{
           display: flex;
           align-items: center;
           margin: 0.5rem;
           .coinsInfo{
               display: flex;
               flex-direction: column;
               align-items: center;
               margin-right: 4.12rem;
               .img{
                   img{
                       width: 145.84px;
                       
                   }
               }
               strong{
                   color: #262A34;
                   font-size: 2rem;
               }
               span{
                   color: #EA8206;
                   font-weight: bolder;
                   font-size: 1.2rem;
               }

           }
           table{
               width: 100%;
               tr{
                border-top: 1px solid #E1E8F3;
                height: 40px;
                font-size: 1.2rem;
                color: #262A34;
                    td{
                       
                    }
                    &:nth-child(3){
                        font-size: 1.5rem;
                    }
               }
               .selectCard{
                   
                   border: none;
                   padding: 5px;
                   color: #333;
                   background-color: transparent;
                   outline: none;
                   option{
                       padding: 5px !important;
                       
                   }
               }
           }
       }
       .lgpd{
          font-size: 1.2rem;
           width: 100%;
           margin: 0 auto;
           text-align: center;
           margin-top: 1rem;
           color: #828BA1;
           @media(min-width: 600px){
             font-size: 1.3rem;
           }
       }
       .groupOfButtons{
           display: flex;
           margin-top: 2rem;
           button{
               flex: 1;
               margin: 0.5rem;
               height: 53px;
               display: flex;
               align-items: center;
               justify-content: center;
               border-radius: 8px;
               font-weight: bolder;
               font-size: 1.4rem;
               transition: all ease 0.2s;
               &:disabled{
                    background-color: rgb(218, 214, 214) !important;
                    color: #333 !important;
                    cursor: not-allowed;
                    position: relative;
                    &::before{
                        contain: 'Seu carrinho está vazio' !important;
                        position: absolute;
                        top: -10px;
                    }
               }
               &:nth-last-child(2){
                   background-color: white;
                   border: none;
                   color: #00C1A5;
                 
                   &:hover{
                       background-color:#00C1A5 ;
                       color: white;
                       filter: brightness(0.9);
                   }
               }
               &:nth-last-child(1){
                background-color: #00C1A5;
                border: none;
                color: white;
                &:hover{
                    
                    filter: brightness(0.9);
                }
            }
           }
           .active{
           
                background-color: #00C1A5 !important;
                border: none;
                color: white !important;
                &:hover{
                    
                    filter: brightness(0.9);
                }
            
          
           }
       }
   }
}