@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
:root{
  font-size: 60%;
  --color-background: #FFF;
  --color-primary-light: #262A34;
  --color-primary-dark: #181A20;
  --color-primary-dark-transparent: #181A20a4;

 
  --color-text-lighter: #FFF;
  --color-text-light: #D8E0F2;
  --color-text-off-white: #FFF0D3;
  --color-text-base: #00C1A5;
  --color-text-medium: #828BA1;
  --color-text-dark: #262A34;
  --color-text-shine: #EA8206;

  --color-primary: #00C1A5;
  --color-secondary: #FEBB22;
  --color-select: #E1E8F3;
  --color-header: #1A1B20;

  --color-success: #00C1A5;
}

*{
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
 
html, body{
  height:100vh;
  margin:0; 
  padding:0;
}
 
body{
  background-color: #fff;
}
 
#root{
  min-height: calc(100vh - var(--bodyPaddingTop));
  display: flex;
  flex-direction: column;
}

.bread-crumbs{
  margin-bottom: 1rem;
  a{
    margin-right: 2rem;
    text-decoration: none;
    color: #828BA2;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    display: inline-flex;
    align-items: center;

    &::before{
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      right: -10px;
      border-right: 2px solid #828BA2 ;
      border-bottom: 2px solid #828BA2 ;
      transform: rotate( -40deg);
    }
    &:nth-last-child(1){
      &::before{
        border: none;
      }
     
    }

  }
}

.container{
  width: 87% !important;
  margin: 0 auto;
  @media(max-width:600px){
    width: 90% !important;
  }

}

@media(min-width: 700px){
  :root{
   font-size:62.5%
  }
}
