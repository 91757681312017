.content{
  font-size: 16px;
  display: flex;

  main{
    padding: 24px 16px;
    color: #828BA1;
    flex: 1;
    max-width: 780px;
    margin: 0 auto;
    
    .title{
      color: #262A34;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .form{
      max-width: 345px;

      .formGroup{
        display: flex;
        flex-direction: column;

        margin: 4px 0;
        
        label{
          padding: 12px 27px;
          font-size: 12px;
          line-height: 14px;
        }

        input{
          border: 1px solid #E1E8F3;
          border-radius: 8px;
          
          width: 100%;
          
          padding: 10px 27px;

          &.readOnly{
            background: var(--color-select);
            outline: none;

            color: #828BA1
          }
        }

        .inputGroup{
          position: relative;

          
          button{
            position: absolute;
            right: 2px;
            top: 2px;
            
            background: transparent;
            border: none;
            border-radius: 8px;
            font-weight: bold;
            
            padding: 9px 27px;

            color: var(--color-text-base);
            background: #fff;

            &.noChange{
              color: #FD6151;
            }
          }
        }
      }

      button[type=submit]{
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 42px 0;
        padding: 10px 32px;

        width: 100%;
        height: 53px;

        background: var(--color-primary);
        color: #FFFFFF;
        border-radius: 8px;
        border: none;

        font-family: Roboto;
        font-size: 16px;
        line-height: 33px;

        text-decoration: none;
      }
    }
    .goBack{
      background: transparent;
      border: none;
      outline: none;
      color: #828BA1;
      text-decoration: underline;
      text-align: center;
      display: block;
      width: 100%;

      margin-bottom: 42px;
    }
  }
}

.tableHeader {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  line-height: 35px !important;
  @media only screen and (min-width: 700px){
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }
}
.tableBody {
  font-size: 1.3rem !important;
  line-height: 30px !important;
  @media only screen and (min-width: 700px){
    font-size: 1.4rem !important;
  }
}
.tablePagination {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  line-height: 35px !important;
  @media only screen and (min-width: 700px){
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }

  p,div {
    font-size: 1.3rem !important;
    font-weight: bold !important;
    line-height: 35px !important;

    @media only screen and (min-width: 700px){
      font-size: 1.5rem !important;
      line-height: 30px !important;
    }
  }
  p {
    margin-bottom: 0px !important;
  }
  svg {
    width: 2em;
    height: 2em;
  }
  ul {
    font-size: 1.3rem !important;
    font-weight: bold !important;
    line-height: 35px !important;
  }
  li, li.MuiMenuItem-root, .MuiMenuItem-gutters, .MuiButtonBase-root, .MuiTablePagination-menuItem {
    font-size: 1.3rem !important;
    font-weight: bold !important;
    line-height: 35px !important;
  }
  .MuiSelect-select {
    font-size: 1.3rem !important;
    font-weight: bold !important;
    line-height: 35px !important;
  }
}

