.orderItemResumeTableHeader {
  font-size: 1.4rem !important;
  font-weight: bold !important;
  line-height: 35px !important;
  @media only screen and (min-width: 700px){
    font-size: 1.5rem !important;
    line-height: 30px !important;
  }
}
.orderItemResumeTableBody {
  font-size: 1.3rem !important;
  line-height: 30px !important;
  @media only screen and (min-width: 700px){
    font-size: 1.4rem !important;
  }

  & >.last{
    padding-right: 10px !important;
  }

  & >.first{
    padding-left: 10px !important;
  }
}
