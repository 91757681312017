
.container{
    padding-top: 5rem;
    min-height: calc(100vh - 10vh);
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
    h1{
        margin-left: 20px;
    }

    .content{
        display: flex;
        position: relative;
        flex: 1;
        @media(max-width: 600px){
            flex-direction: column;
            padding-left: 1rem;
            padding-right: 1rem;
        }
      .title{
          font-size: 2rem;
          font-weight: bold;
          &:nth-child(1){
              margin-left: 10px;
          }
        }
        .contentRight{
            flex: 1;
            flex-direction: column;
            padding-left: 1rem;
            padding-right: 1rem;
            min-height: 100%;
            @media(min-width: 600px){
                max-width: 300px;
            }
          
        }
        
                  
    }
}

.contentLeft{
    flex: 1;
    position: relative;
    
    .options{
       
        span{
            margin-left: 20px;
        }
        .row{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            padding: 10px;
            .col{
              flex: 1;
              min-width: 100%;
              padding: 0.625rem;
              
               // s6
                // @media(max-width:600px){
                //     max-width:calc( 8.333% * 6); 
                //     min-width:calc( 8.333% * 6);
                //    
                
                // }
                // // m3
                // @media(min-width: 600px){
                //     max-width:calc( 8.333% * 4); 
                //     min-width:calc( 8.333% * 4);
                //     padding: 0.983rem;
                // }
                .cardStyle{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 50px;
                    padding: 1rem 1.5rem;
                    background-color: #F8F8F8;
                    border: 1px solid #E1E8F3;
                    border-radius: 8px;
                    cursor: pointer;
                    font-size: 1.5rem;
                    div{
                        display: flex;
                        align-items: center;
                    }
                }
                button{
                   width: 100%;
                   border: none;
                }
                .cardIconContainer {
                  margin-left: 15px;
                }
            }
        }

    }
}

.noMarginLeft {
  margin-left: 0px !important;
}

.orderSummary{
    display: flex;
    flex-direction: column;
     position: sticky;
        top: 0;
    table{
        margin-top: 1rem;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c2c2c2;
        border-radius: 8px;
        padding-left: 1rem;
        padding-right: 1rem;

        tr{
            th{
                border-bottom: 1px solid #c2c2c2;
                padding: 10px;

            }
            td{
                border-bottom: 1px solid #c2c2c2;
                text-align: right;
                
            }
         &:nth-last-child(1){
            th{
                border: none;
            }
            td{
                border: none;
            }
         }  
        }
    }
    .cupomContainer{
        margin-top: 1rem;
        margin-bottom: 3rem;
        .buttonGroup{
            display: flex;
            margin-top: 1rem;
            input{
                flex: 1;
                height: 41px;
                margin-right: 10px;
                border: none;
                background-color: #e1e8f3;
                padding: 10px;
                border-radius: 8px;
            }
            button{
                flex: 1;
               max-width: 91px;
               background-color: #00C1A5;
               color: white;
               border: none;
               font-weight: bolder;
               font-size: 1.4rem;
               border-radius: 8px;
               &:hover{
                   filter: brightness(0.9);
                   
               }
            }
        }
    }
    .action{
        background-color: #00C1A5;
        border: none;
        height: 53px;
        color: white;
        font-size: 1.4rem;
        font-weight: bolder;
        margin-top: 1rem;
        border-radius: 8px;
    }
    a{
       
        text-align: center;
        margin-top: 1rem;
        color: #00C1A5;
        font-weight: bolder;
        font-size: 1.5rem;
    }
}

.addNewCard{
    .layoutContainer{
        padding: 20px;
        display: flex;
        form{
            flex: 1;
       
            display: flex;
            flex-direction: column;
            input, select{
                padding: 1rem;
                margin-bottom: 2rem;
                border: 1px solid rgb(146, 146, 146);
                background: #FFFFFF;
                font-size: 1.4rem;
                font-weight: lighter;
                border-radius: 8px;
                outline: none;
            }
            label{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                font-size: 1.4rem;
                input{
                    margin: 0 10px;
                    width: 20px;
                    height: 20px;
                }
            }
            div{
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 2rem;
                label{
                    flex: 1;
                    display: flex;
                  &:nth-child(1){
                      margin-right: 5px;
                  }
                  &:nth-child(2){
                    margin-left: 5px;
                }
                  
                    span{width: 100%;}
                    flex-direction: column;
                    input{
                        width: 100%;
                        height: 40px;
                        
                       
                    }
                   
                }
                
            }
        }

       
    }
}
.flagCard{
    display: flex;
    justify-content: space-between !important;
    svg{
        background-color: #00C1A5;
    }
}