.container{
    padding-top: 5rem;
    min-height: calc(100vh - 10vh);
    .conteinerPaymentOptions{
        max-width: 600px;
        margin: 0 auto;
        .steps{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 3rem;
            button{
                background-color: transparent;
                border: none;
            }
            span{
                font-size: 1.3rem;
                color: #919090 ;
            }
        }
        .PaymentOption{
            border: 1px solid #acacac;
            border-radius: 10px;
            padding: 1rem;
            margin-top: 2rem;

            .paimenType{
                display: flex;
               justify-content: space-between;
               align-items: center;
               cursor: pointer;
               text-decoration: none;
               color: #5c5c5c;
               span{ margin-left: 10px; font-size: 1.2rem;} 
               button{
                   background-color: transparent;
                   border: none;
               }

            }
            
        }
    }
}