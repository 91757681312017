.content{
  font-size: 16px;
  display: flex;

  main{
    padding: 24px 16px;
    flex: 1;
    max-width: 100%;

    h3{
      color: #262a34;
      font-weight: 900;
      font-size: 24px;
      line-height: 28.13px;
      margin-bottom: 16px;
    }

    .description{
      color: #828BA1;
      font-size: 14px;
    }

    .containerCards{
      margin: 32px 0;

      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .card{
        border: 1px solid #E1E8F3;
        border-radius: 16px;

        padding: 22px;
        min-width: 245px;

        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        text-align: center;

        > svg{
          height: 122px;
          width: 122px;
        }

        .btnRedirect{
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 0;
          padding: 10px 32px;

          width: 100%;
          height: 53px;

          background: var(--color-primary);
          color: #FFFFFF;
          border-radius: 8px;
          border: none;

          font-family: Roboto;
          font-size: 16px;
          line-height: 33px;

          text-decoration: none;
        }
      }
    }
  }
}