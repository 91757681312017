.content{
  font-size: 16px;
  display: flex;

  main{
    padding: 24px 16px;
    color: #828BA1;
    flex: 1;
    max-width: 100%;
    
    .title{
      color: #262A34;
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 40px;
    }

  }
}