.banner{
  width: 100%;
  border-radius: 8px;
  position: relative;
  height: auto;
  min-height: 200px;

  &::after{
    content: '';
    display: block;
    @media(max-width: 600px){
      padding-top: 50%;
    }
    padding-top: 30%;
  }
  .carousel{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .left, .rigth{
      position: absolute;
      top: calc(50% - 27px);
      width: 54px;
      height: 54px;
      background-color: #313641;
      border-radius: 50%;
      border: none;
      @media(max-width: 600px){
        display: none !important;
      }

    }
    .left{
      left: -27px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        transform: rotate(45deg);
      }
    }
    .rigth{
      right: -27px;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-right: 1px solid white;
        border-top: 1px solid white;
        transform: rotate(45deg);
      }
    }
    .slider{
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow-x: hidden;
      display: flex;
      .containerItems{
        width: 100%;
        height: 100%;
        .item{
          width: 100%;
          height: 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain ;

        }
      }
    }
  }
}
.coinsSection{
  width: 100%;
  position: relative;
  cursor: pointer;
  img{
    width: 100%;
    padding: 1rem;
  }
}