.container{
  min-height: calc(100vh - 120px);
  padding: 22px;

  width: 100%;
  margin: auto;

  @media (min-width: 576px){
    max-width: 540px;
  }
  @media (min-width: 768px){
    max-width: 720px;
  }
  @media (min-width: 992px){
    max-width: 960px;
  }
  @media (min-width: 1200px){
    max-width: 1100px;
  }
}