.container{
    font-family: Roboto;
    padding-bottom: 3rem;

    .breadcrumbs{
        ul{
            list-style: none;
            padding: 1.2rem 0;
            padding-left: 0;

            li{
                display: inline-flex;
                align-items: center;
                
                a{
                    line-height: 16px;
                    font-size: 14px;
                    color: var(--color-text-medium);
                    text-decoration: none;
                    padding-right: 14px;
                }

                &:not(:first-child){
                    a{
                        padding-left: 14px;
                    }
                }

                &:last-child{
                    a{
                        font-weight: bold;
                    }
                }
            }
        }
    }
    
    .stepGroup{
        margin: 32px 15px 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        .stepItem{
            display: flex;
            align-items: center;
            justify-content: center;
            
            border-radius: 50%;
            width: 20px;
            height: 20px;

            margin: 0 6px;
            border: 2px solid var(--color-text-medium);
            
            font-size: 12px;
            line-height: 16px;

            color: var(--color-text-medium);

            &.active{
                background: var(--color-text-medium);
                color: white;
                
                width: 28px;
                height: 28px;
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
            }
        }

        .separator{
            width: 8px;
        }
    }

    .content{
        max-width: 345px;
        padding: 0 15px;
        margin: 0 auto;
    
        .title{
            text-align: center;
            font-weight: bold;
            max-width: 279px;
            margin: 0 auto 45px;

            color: var(--color-primary-light);
        }

        fieldset{
            margin-bottom: 12px;
        }

        .formGroup{
            width: 100%;
            margin: 4px 0 8px;

            input{
                width: 100%;

                height: 45px;
                
                padding: 6px 27px;
                padding-right: 6px;

                background: var(--color-select);
                color: var(--color-text-medium);

                border: none;
                border-radius: 8px;

                font-family: Roboto;
                font-size: 14px;
                line-height: 16px;
            }

            span{
                text-align: center;
                margin: 11px auto;
                display: block;

                color: var(--color-text-medium);
                font-size: 12px;
                line-height: 14px;
            }

            .errorMessage{
                display: none;
                color: #FD6151;
                margin: 8px;
            }

            &.formError{
                input{
                    border: 2px solid #FD6151;
                }
                .errorMessage{
                    display: inline-block;
                }
            }
        }

        .customCheckbox{
            width: fit-content;
            max-width: 100%;
            margin: 24px auto 42px;
            display: block;

            label{
                display: flex;
                align-items: center;

                p{
                    margin: 0;
                    padding: 0 8px;
                    text-align: left;
                    font-size: 12px;
                }
            }
        }

        .btnSubmit{
            display: flex;
            justify-content: center;
            align-items: center;

            margin: 32px 0 12px;
            padding: 10px 32px;

            width: 100%;
            height: 53px;

            background: var(--color-primary);
            color: #FFFFFF;
            border-radius: 8px;
            border: none;

            font-family: Roboto;
            font-size: 16px;
            line-height: 33px;
        }
        
        .textInfo{
            text-align: center;
            color: var(--color-text-medium);

            font-size: 14px;
            line-height: 16px;

            a{
                color: inherit;
                font-weight: bold;
            }
        }
    }

}

@media (max-width: 700px){
    .container{
        .breadcrumbs{ display: none; }
        .stepGroup{
            justify-content: flex-start;
        }
        .content{
            .title{
                text-align: left;
                margin-left: 0;
            }
        }
    }
}